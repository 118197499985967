:root {
    --primary: #FF0000;
    --primary-hover: #FF0000;
}

html,
body {
    /* height: 100%; */
    min-height: 100vh;
}

#root {
    /* height: 100%; */
    min-height: 100vh;
    display: grid;
}

#root>.ant-layout {
    height: 100%;
    min-height: 100%;
}

#root>.login-form {
    width: 50%;
    margin: auto;
    padding: 12px 48px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

button[type=submit] {
    display: block;
    width: 100%;
    margin: 3% auto;
    color: #fff;
    font-weight: 600;
}

button[type=submit]:hover {
    color: #fff;
}

.logo {
    height: 125px;
    display: block;
    margin: 0 auto;
}


button[type=submit],
.ant-btn-primary {
     background-color: var(--primary);
     border-color: var(--primary);
}

button[type=submit]:hover,
.ant-btn-primary:hover {
     background-color: var(--primary-hover);
     border-color: var(--primary-hover);
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: var(--primary);
    color: var(--primary);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    color: white;
}

.manage-section-wrapper {
    margin: 2%;
}

.manage-section-wrapper > .ant-select {
    width: 25%;
}

.manage-section-wrapper > .ant-table-wrapper {
    margin-top: 2%;
}

.add-fab {
    position: relative;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}

.add-fab:hover {
    cursor: pointer;
}

.export-btn {
    font-size: 48px;
    color: #1890ff;
}

.export-btn-only {
    font-size: 48px;
    color: #1890ff;
}


.export-btn-only,
.export-btn,
.add-series-btn {
    position: relative;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}

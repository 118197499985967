.ant-layout-header {
    background-color: #000;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    height: 64px;
}

.ant-menu.ant-menu-dark {
    background-color: #000;
}

.logout-btn:hover {
    background-color: var(--primary-hover) !important;
    color: white !important;
}
